<script setup>
    /* eslint-disable jethr/imports-order */
    import {api} from '@/api';
    import {useSpaStore} from '@/stores/spa.js';
    import ImpersonationSession from '@jetCommon/components/ImpersonationSession.vue';
    import {initJetTools} from '@jetCommon/jet-tools.js';
    import it from '@jetCommon/locale.js';
    import settings from '@/env.js';
    import {usePushNotifications} from '@jetCommon/composables/push-notifications.js';

    import {ElConfigProvider} from 'element-plus';
    import 'element-plus/es/components/config-provider/style/index';

    const spaStore = useSpaStore();

    const jetTools = initJetTools({
        api,
        routeEntityName: settings.JET_ROUTE_ENTITY_NAME,
        enableAuthStore: true,
        spaStore,
    });
    provide('jet', jetTools);

    import {storeToRefs} from 'pinia';
    const {isImpersonationSession, person} = storeToRefs(jetTools.$authStore);

    const {registerListenForToken, registerListenForNotification} = usePushNotifications();
    registerListenForToken();
    registerListenForNotification();
</script>

<template>
    <ElConfigProvider :locale="it">
        <ImpersonationSession
            v-if="isImpersonationSession"
            :initial-remaining-seconds="person.impersonation_remaining_seconds" />
        <router-view />
    </ElConfigProvider>
</template>
