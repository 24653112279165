import HiringApiCommon from '@jetCommon/api/hiring.js';

export default class HiringApi extends HiringApiCommon {
    getByUUID(uuid) {
        const params = {uuid};
        return this.apiGet('retrieve_by_uuid/', {params});
    }

    signatureLinkByUUID(uuid) {
        const params = {uuid};
        return this.apiGet('signature_link_by_uuid/', {
            params,
            validateStatus: status => [200, 425].includes(status),
        });
    }

    completeEmployeeData(data) {
        return this.apiPost('complete_employee_data/', data);
    }
}
