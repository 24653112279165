/* eslint-disable jethr/imports-order */
import App from './App.vue';
const app = createApp(App);

import '@jetDS/scss/main.scss';
import '@/assets/styles/jet-employee.scss';
import '../../fontawesome_icons.js';

import {createPinia} from 'pinia';
const pinia = createPinia();
app.use(pinia);

import router from './router.js';
app.use(router);

import vueQueryConfig from '@jetCommon/queries/defaultQueryClientConfig.js';
import {VueQueryPlugin} from '@tanstack/vue-query';

app.use(VueQueryPlugin, vueQueryConfig);

import settings from '@/env.js';

import {createGtm} from '@gtm-support/vue-gtm';
app.use(
    createGtm({
        id: 'GTM-NF6JWDV',
        defer: false,
        compatibility: false,
        nonce: '2726c7f26c',
        enabled: settings.GOOGLE_TAG_MANAGER.enabled,
        debug: settings.GOOGLE_TAG_MANAGER.debug,
        loadScript: true,
        trackOnNextTick: false,
        vueRouter: router,
    }),
);

import {ElLoading} from 'element-plus';
import 'element-plus/es/components/loading/style/index';
app.directive('loading', ElLoading.directive);

import {initSentry} from '@jetCommon/sentry.js';
initSentry({
    app,
    router,
    sentrySettings: settings.SENTRY_SETTINGS,
    spaName: settings.SENTRY_TAG_SPA,
});

import '@jetCommon/handle-preload-error.js';

app.mount('#app');
